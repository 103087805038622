export default {
	data () {
		return {

			/*LANGUAGE BUTTON*/
			/* can be 'language' or 'country' */

			modelLangButton: 'language',

			/* For CLIENT in GDPR, see the translation files */

			/* FIRST PAGE */
			/* usual values: 'landing', 'instructions', 'form1' */
			firstPageOfThisForm: 'form1',

			/* For P&G */
			/* If true, allow OneTrust tag and remove GDPR, Matomo, CookieFirst… */
			/* If false… Don't forget to update the IDs and CODES on mixins "ga" and "cf" for google analytics and cookie first */
			isPnGAction: true,

			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,

			/* BREADCRUMBS */
			displayBreadCrumbs: true,
			listOfCrumbsForThisAction: ['store', 'form', 'survey', 'gift'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			
			/* DEFAULT LOCALE */
			/* Useful to force nl_NL instead of nl_BE */
			/* ! See in the i18n.js file */

			/* Allow a coupon in the mail (if survey is filled) */
			aCouponCouldBeSent: false,
			/* Is there a survey at all ?*/
			thereIsASurveyInThisAction: false,

			/* Is there a visible counter? */
			counterIsVisible: false,

			/* REQUIRED FIELDS to UNLOCK BUTTONS */
			/* List of fields that must be valid for a specific button to unlock */
			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['gender','firstName', 'lastName', 'email'],
				'btn_cashback_on_form2' : ['selectProduct', 'file', 'conditions', 'optin2'],
				'btn_cashback_on_survey': ['idLeadTMD1', 'idLeadTMD2'],
				'btn_cashback_on_printcoupon': ['conditionsCoupons'],
			},
			/* REQUIRED FIELDS to UNLOCK THE FORM */
			/* Ultime check to see if something is missing despite the buttons check */
			/* Must never include optionnel answers like survey's */
			allRequiredFields: ['gender','firstName', 'lastName', 'email', 'selectProduct', 'file', 'conditions', 'optin2', 'idLeadTMD1', 'idLeadTMD2'],

			/* ! "countries" must not be required when the google API put the value in the field, as it is not validated by action api then. */
			/* TODO: find a way to make action api validate that field */

			/* ALL default fieldname that can be checked
					'idTitle',
					'gender',
					'firstName',
					'lastName',
					'email',
					'phoneNumber1',
					'IBAN', 
					'VATNumber',
					'street1',
					'houseNumber',
					'boxNumber',
					'postalCode',
					'city',
					'countries',
					'selectProduct',
					'purchaseDate',
					'idPOPRetailer',
					'freeRetailerName',
					'idLeadTMD1', 'idLeadTMD2',…
					'serialNumber',
					'file',
					'conditions',
					'uniqueCode'

			*/

			

		}
	},
}
